@font-face {
  font-family: 'gt-pressura-bold';
  src: local('gt-pressura-bold'), url(fonts/gt-pressura-bold-web-webfont.ttf) format('truetype');
}
@font-face {
  font-family: 'gt-pressura-bold-italic';
  src: local('gt-pressura-bold-italic'), url(fonts/gt-pressura-bold-italic-web-webfont.ttf) format('truetype');
}
@font-face {
  font-family: 'gt-pressura-light';
  src: local('gt-pressura-light'), url(fonts/gt-pressura-light-web-webfont.ttf) format('truetype');
}
@font-face {
  font-family: 'gt-pressura-light-italic';
  src: local('gt-pressura-light-italic'), url(fonts/gt-pressura-light-italic-web-webfont.ttf) format('truetype');
}
@font-face {
  font-family: 'gt-pressura';
  src: local('gt-pressura'), url(fonts/gt-pressura-regular-web-webfont.ttf) format('truetype');
}
