.layout {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: calc(90px + 2vmin);
  color: #62edff;
  padding: 40px;
}

.header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: calc(90px + 2vmin);
  color: #62edff;
}

.deviceItem {
  background-color: #282c34;
}
