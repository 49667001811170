.layout {
  text-align: center;
  font-family: 'gt-pressura-bold', sans-serif;
}
.header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: calc(90px + 2vmin);
  color: #62edff;
}
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
.ctalink {
  font-family: 'gt-pressura-bold', sans-serif;
  font-size: 20px;
  color: white;
}
.scan {
  font-family: 'gt-pressura-bold', sans-serif;
  font-size: 20px;
  color: white;
}
